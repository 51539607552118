import { graphql, Link } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { media, theme } from '@dfds-ui/theme'
import { ChevronRight } from '@dfds-ui/icons/system'
import {
  ListItem,
  ListTitle,
  ListText,
  ListIcon,
} from '@dfds-ui/react-components'
import { track } from '@dfds-frontend/tracking'

import { FlexCard } from '../Card'
import { useLocaleContext } from '../LocaleContext'
import { useLocalizedLinkTrackingData } from '../EntryLink/LocalizedLink'

const Container = styled.div`
  columns: 1;
  column-gap: 40px;

  ${media.greaterThan('m')`
    columns: 2;
  `}

  ${media.greaterThan('xl')`
    columns: 3;
  `}
`

const FreightRoutesListBox = (props) => {
  const { localeLower } = useLocaleContext()
  const { getLocalizedLinkTrackingData } = useLocalizedLinkTrackingData()

  return (
    <FlexCard width={{ md: 8 }}>
      <Container>
        {props.routeAreasCollection.items.map((item, index) => (
          <div
            key={index}
            css={css`
              break-inside: avoid-column;
              padding: 0 0 16px 0;
            `}
          >
            <ListTitle
              css={css`
                padding: 0 0 16px 0;
                min-height: 0;
              `}
            >
              <ListText styledAs={'smallHeadline'}>
                {item.freightRouteArea}
              </ListText>
            </ListTitle>
            {item.freightRoutesCollection.items.map((routeLink, index) => (
              <ListItem
                key={index}
                css={css`
                  padding: 0 0 16px 0;
                  min-height: 0;
                `}
              >
                <ListIcon
                  icon={ChevronRight}
                  color={theme.colors.text.secondary.primary}
                />
                <Link
                  to={`/${localeLower}/${routeLink.destination.slug}`}
                  onClick={() =>
                    routeLink?.ctaButtonTracking &&
                    track(getLocalizedLinkTrackingData(routeLink))
                  }
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <ListText styledAs={'action'}>{routeLink.linkText}</ListText>
                </Link>
              </ListItem>
            ))}
          </div>
        ))}
      </Container>
    </FlexCard>
  )
}

export const query = graphql`
  fragment FreightRoutesList on contentful_FreightRoutesListBox {
    __typename
    sys {
      id
    }
    routeAreasCollection(limit: 7) {
      items {
        ... on contentful_FreightRouteList {
          freightRouteArea
          freightRoutesCollection(limit: 14) {
            ... on contentful_FreightRouteListFreightRoutesCollection {
              items {
                ...ItemLink
              }
            }
          }
        }
      }
    }
  }
`

export default FreightRoutesListBox
